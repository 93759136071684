<script>
import bottomSheet from '/~/core/bottom-sheet'
import BaseButton from '/~/components/base/button/base-button.vue'
import ButtonIcon from '/~/components/base/button/icon/button-icon.vue'
import { usePaymentMethods } from '/~/composables/payment-methods'
import DrawerDeletableItem from '../drawer-deletable-item.vue'
import DrawerPaymentFrom from '../rows/drawer-payment-from.vue'

export default {
  name: 'drawer-pay-from-row',
  components: {
    DrawerDeletableItem,
    DrawerPaymentFrom,
    ButtonIcon,
    BaseButton,
  },
  props: {
    from: {
      type: Object,
      required: true,
    },
    deletable: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isNavEnabled: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const { deletePaymentMethod, fetchPaymentMethods, initiatingAccountsIds } =
      usePaymentMethods()

    return {
      deletePaymentMethod,
      fetchPaymentMethods,
      initiatingAccountsIds,
    }
  },
  data() {
    return {
      deleting: false,
    }
  },
  computed: {
    initializing() {
      return this.initiatingAccountsIds.includes(this.from.id)
    },
    status() {
      return this.from?.status?.toLowerCase()
    },
    verificationRequired() {
      return (
        this.isNavEnabled &&
        (this.status === 'new' || this.status === 'pending')
      )
    },
    isLocked() {
      return this.status === 'locked'
    },
  },
  methods: {
    async onDelete() {
      this.deleting = true

      await this.deletePaymentMethod(this.from.id)

      this.deleting = false
    },
    verify() {
      if (this.initializing) {
        return
      }

      bottomSheet.show('method-verify', {
        to: 'menu-modal',
        props: {
          method: this.from,
        },
      })
    },
    onClick() {
      if (this.isLocked) {
        this.fetchPaymentMethods()
      } else if (!this.verificationRequired) {
        this.$emit('click')
      }
    },
  },
}
</script>

<template>
  <drawer-deletable-item :deleting="deleting" @delete-confirmed="onDelete">
    <template #default="{ toggleDelete }">
      <drawer-payment-from
        :from="from"
        :is-disabled="disabled || isLocked"
        :class="verificationRequired && 'cursor-default'"
        @click="onClick"
      >
        <template #action>
          <base-button
            v-if="verificationRequired"
            :loading="initializing"
            color="warning"
            size="xs"
            @click="verify"
          >
            Verify
          </base-button>
          <div
            v-else-if="isLocked"
            class="flex h-[30px] w-[72px] items-center justify-center rounded-md bg-fg-error text-sm font-bold text-white"
          >
            Locked
          </div>

          <span class="text-primary">
            <button-icon
              v-if="deletable && !isLocked && !verificationRequired"
              icon="trash"
              size="md"
              @click.stop="toggleDelete"
            />
          </span>
        </template>
      </drawer-payment-from>
    </template>
  </drawer-deletable-item>
</template>
