<script>
import { nextTick } from 'vue'
import emitter from '/~/core/emitter'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import { useCms } from '/~/composables/cms/use-cms'
import { usePaymentMethods } from '/~/composables/payment-methods'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'
import { useProvider } from '/~/composables/provider'
import DrawerPayFromRow from './drawer-pay-from-row.vue'

export default {
  name: 'drawer-pay-from',
  components: {
    DrawerPayFromRow,
    BaseAsidePage,
    BaseLoader,
  },
  props: {
    title: {
      type: String,
      default: 'Pay from',
    },
    action: {
      type: Function,
      default: null,
    },
  },
  setup() {
    const {
      config: paymentMethodsConfig,
      fetchingList: fetching,
      creditCards,
      noCreditCards,
      bankAccounts,
      noBankAccounts,
      eWallets,
      noEwallets,
      points,
      noPoints,
      isMethodAvailable,
    } = usePaymentMethods()
    const { isBankAccountsNavEnabled, isCreditCardsNavEnabled } = useProvider()
    const { ewalletLabels } = useCms()

    return {
      paymentMethodsConfig,
      fetching,
      creditCards,
      noCreditCards,
      bankAccounts,
      noBankAccounts,
      eWallets,
      noEwallets,
      points,
      noPoints,
      isMethodAvailable,
      isBankAccountsNavEnabled,
      isCreditCardsNavEnabled,
      PaymentMethodType,
      ewalletLabels,
    }
  },
  computed: {
    source() {
      return this.$router.currentRoute.params.source
    },
  },
  methods: {
    isAvailable(type) {
      return this.source ? this.isMethodAvailable(type, this.source) : true
    },
    isEnabled(from) {
      if (!this.source) {
        return true
      }

      const config = this.paymentMethodsConfig.find(
        (item) => item.type === from.type
      )?.config

      return Boolean(config?.[this.source]?.enabled)
    },
    selectPayFrom(from) {
      emitter.emit('menu:pay-anyone:select-from', from)
      this.$router.back()
    },
    setFocus() {
      nextTick(() => {
        this.$refs.baseAsidePage.setFocus()
      })
    },
  },
}
</script>

<template>
  <base-aside-page ref="baseAsidePage" :title="title" :action="action">
    <template v-if="isAvailable(PaymentMethodType.eWallet)">
      <div class="mb-2.5 font-bold text-eonx-neutral-600">
        <slot name="eWalletsLabel">{{ ewalletLabels.ewallet }}</slot>
      </div>
      <div v-if="fetching" class="mx-auto py-5">
        <base-loader />
      </div>
      <template v-else>
        <div v-if="noEwallets" class="mb-6 mt-[5px] font-bold">
          <slot name="eWalletsEmptyLabel">
            No Available {{ ewalletLabels.ewallets }}
          </slot>
        </div>
        <div v-else class="mb-[30px] space-y-2.5">
          <drawer-pay-from-row
            v-for="ewallet in eWallets"
            :key="ewallet.id"
            :from="ewallet"
            :deletable="false"
            :disabled="!isEnabled(ewallet)"
            @click="selectPayFrom(ewallet)"
          />
        </div>
      </template>
    </template>

    <template v-if="isAvailable(PaymentMethodType.points)">
      <div class="mb-2.5 font-bold text-eonx-neutral-600">
        <slot name="pointsLabel">Points</slot>
      </div>
      <div v-if="fetching" class="mx-auto py-5">
        <base-loader />
      </div>
      <template v-else>
        <div v-if="noPoints" class="mb-6 mt-[5px] font-bold">
          <slot name="pointsEmptyLabel">No Available Points</slot>
        </div>
        <div v-else class="mb-[30px] space-y-2.5">
          <drawer-pay-from-row
            v-for="pointsItem in points"
            :key="pointsItem.id"
            :from="pointsItem"
            :deletable="false"
            :disabled="!isEnabled(pointsItem)"
            @click="selectPayFrom(pointsItem)"
          />
        </div>
      </template>
    </template>

    <template v-if="isAvailable(PaymentMethodType.creditCards)">
      <div class="mb-2.5 font-bold text-eonx-neutral-600">
        <slot name="cardsLabel">Credit/Debit Cards</slot>
      </div>
      <div v-if="fetching" class="mx-auto py-5">
        <base-loader />
      </div>
      <template v-else>
        <div v-if="noCreditCards" class="mb-6 mt-[5px] font-bold">
          <slot name="cardsEmptyLabel">No Available Credit/Debit Cards</slot>
        </div>
        <div v-else class="mb-[30px] space-y-2.5">
          <drawer-pay-from-row
            v-for="card in creditCards"
            :key="card.id"
            :from="card"
            :disabled="!isEnabled(card)"
            :is-nav-enabled="isCreditCardsNavEnabled"
            @click="selectPayFrom(card)"
          />
        </div>
      </template>
    </template>

    <template v-if="isAvailable(PaymentMethodType.bankAccount)">
      <div class="mb-2.5 font-bold text-eonx-neutral-600">
        <slot name="bankAccountsLabel">Bank Accounts</slot>
      </div>
      <div v-if="fetching" class="mx-auto py-5">
        <base-loader />
      </div>
      <template v-else>
        <div v-if="noBankAccounts" class="mb-6 mt-[5px] font-bold">
          <slot name="bankAccountsEmptyLabel">No Available Bank Accounts</slot>
        </div>
        <div v-else class="space-y-2.5">
          <drawer-pay-from-row
            v-for="account in bankAccounts"
            :key="account.id"
            :from="account"
            :disabled="!isEnabled(account)"
            :is-nav-enabled="isBankAccountsNavEnabled"
            @click="selectPayFrom(account)"
          />
        </div>
      </template>
    </template>
  </base-aside-page>
</template>
